import React from 'react';
import Markdown from 'react-markdown'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

export default function DialogsMessages(props) {

    const { messages } = props;

    // console.log(messages)
    
    return messages?.role !== "system" && (
        <div className={(messages?.outgoing === false || messages?.role === "user") ? "message-item message-self" : "message-item"}>
            <div className="message-user">
                {/* <div className="avatar">
                    <span className="avatar-text">
                        <i className={(messages?.outgoing === false || messages?.role === "user") ? "feather-user" : "feather-rss"}></i>
                    </span>
                    <span className="status online"></span>
                </div> */}
            </div>
            <div className="message-wrap">
                <div className="message-content">
                    <div className="message-info">
                        <div className="message-text">
                           {/* {(messages?.outgoing === false || messages?.role === "user") ? 
                              <FroalaEditorView
                                 model={messages?.content}
                              />
                           : 
                              <Markdown>
                                 {messages?.content}   
                              </Markdown>
                           } */}
                           {messages?.content} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    {/* <div className="message-item message-self">
       <div className="message-user">
          <div className="avatar">
             <img src="assets/img/account/08.jpg" alt="" />
          </div>
       </div>
       <div className="message-wrap">
          <div className="message-content">
             <div className="message-info">
                <div className="message-text">
                   <div className="message-time"><i className="far fa-check-double"></i> 08:15 AM</div>
                   <p>Hello! Brandi Good morning I'm sorry, I didn't catch that. Could you please repeat for me again?.</p>
                </div>
                <div className="message-action">
                   <div className="dropdown">
                      <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="feather-more-vertical"></i>
                      </button>
                      <ul className="dropdown-menu">
                         <li><a className="dropdown-item" href="#"><i className="feather-copy"></i>Copy</a></li>
                         <li><a className="dropdown-item" href="#"><i className="feather-corner-up-right"></i>Forward</a></li>
                         <li>
                            <hr className="dropdown-divider" />
                         </li>
                         <li><a className="dropdown-item" href="#"><i className="feather-trash-2"></i>Remove</a></li>
                      </ul>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div> */}
}