import {createSlice} from '@reduxjs/toolkit'
import {integrationsList} from './action/list'
import {integrationsApps} from './action/apps'
import {integrationsCreate} from './action/create'
import {integrationsCheck} from './action/check'


const initialState = {
	list: null,
	check: null,
	loading: false,
	create: true,
	apps: null
}

const slice = createSlice({
	name: 'integrations',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(integrationsList.pending, (state) => {
			state.loading = true
		})
		builder.addCase(integrationsList.fulfilled, (state, action) => {
			state.list = action.payload
			state.loading = false
		})
		builder.addCase(integrationsApps.pending, (state) => {
			state.loading = true
		})
		builder.addCase(integrationsApps.fulfilled, (state, action) => {
			state.apps = action.payload
			state.loading = false
		})
		builder.addCase(integrationsCreate.pending, (state) => {
			state.loading = true
		})
		builder.addCase(integrationsCreate.fulfilled, (state, action) => {
			state.create = action.payload
			state.loading = false
		})
		builder.addCase(integrationsCheck.pending, (state) => {
			state.loading = true
		})
		builder.addCase(integrationsCheck.fulfilled, (state, action) => {
			state.check = action.payload
			state.loading = false
		})
	}
})

export const {actions : integrationsActions} = slice
export const {reducer : integrationsReducer} = slice
