import {createAsyncThunk} from '@reduxjs/toolkit'
import {checkIntegrationsAPI} from '../../api/check'


export const integrationsCheck = createAsyncThunk('INTEGRATIONS/CHECK', async args => {
	let res = await checkIntegrationsAPI(args)
	return res
})



