import React, { useState, useEffect, useMemo } from 'react';
import CardDialogs from '../../../components/Card/CardDialogs';

export default function DialogsList(props) {

    const { loadProfileChatStartChatList, onClickHandlerChangeProfileChatChatList, list } = props;
    // console.log(list)
    return <div className="contact-list scrollbar-wrap">
                <div className="contact-list-wrap">
                    {list.map((item, index) => (
                        
                        <CardDialogs
                            key={index}
                            idx={index}
                            item={item}
                            loadProfileChatStartChatList={loadProfileChatStartChatList}
                            onClickHandlerChangeProfileChat={(value) => onClickHandlerChangeProfileChatChatList(value)}
                        />
                    ))}
                </div>
            </div>
}