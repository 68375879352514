import {post} from '../../../api/post'


export const checkIntegrationsAPI = async (data) => {
	try {
		const response = await post('/telegram/methods/', data);
		if(response.status === 'success'){
			return response.response
		} else {
			return null
		}
	} catch (error) {
		throw error;
	}
};