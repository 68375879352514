import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaneDialogs from '../modules/Pane/PaneDialogs'; 
import DialogsMessages from '../modules/Dialogs/components/DialogsMessages'; 
import ProfileMore from '../modules/Profile/ProfileMore'; 
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { ProfileChatStart, ProfileChatHeader } from '../components/Profile/Profile'; 
import {selectDialogs} from '../modules/Dialogs/model/selectors/selectDialogs'
import {selectDialogsLength} from '../modules/Dialogs/model/selectors/selectDialogsLength'
import {selectDialogsMessages} from '../modules/Dialogs/model/selectors/selectDialogsMessages'
import { dialogsList } from '../modules/Dialogs/model/action/list'
import { dialogsLength } from '../modules/Dialogs/model/action/length'
import { dialogsMessages } from '../modules/Dialogs/model/action/messages'

export default function Dialogs() {

    const dispatch = useDispatch(); // Получаем функцию dispatch из Redux
    const dialogs_list = useSelector(selectDialogs)
    const dialogs_length = useSelector(selectDialogsLength)
    const dialogs_messages = useSelector(selectDialogsMessages)
    // console.log(dialogs_messages)
    let authorized = 0
    let user_id = 0
    let username = 0
  
    let cookie_split = document.cookie.split(';');
    for (let i = 0; i < cookie_split.length; i++) {
      let cookie_name_split = cookie_split[i].split('=');  
      if(cookie_name_split[0].replace(/\s+/g, '') === "authorized"){
        authorized = cookie_name_split[1]
      }
      if(cookie_name_split[0].replace(/\s+/g, '') === "user_id"){
        user_id = cookie_name_split[1]
      }
      if(cookie_name_split[0].replace(/\s+/g, '') === "username"){
        username = cookie_name_split[1]
      }
    }

    const [loadProfileDialogstart, setProfileDialogstart] = useState(null);
    const [loadProfileInfo, setProfileInfo] = useState(false);
    const [loadProfileData, setProfileData] = useState(0);

    const handleProfileDialogstart = (value) => {
        console.log(dialogs_list[value])
        setProfileDialogstart(value);
        // console.log(dialogs_list[value][0]["existing"]["chat_id"])
        // console.log(dialogs_list[value][0]["existing"]["integration_id"])
        // console.log(dialogs_list[value][0]["existing_table"]["user_id"])
        // if(value !== null){
        //     setProfileData(value);
        //     let chat_id = dialogs_list[value][0]["existing"]["chat_id"]
        //     let user_id = dialogs_list[value][0]["existing_table"]["user_id"]
        //     let integration_id = dialogs_list[value][0]["existing"]["integration_id"]

        //     const data = {
        //         user_id: user_id,
        //         chat_id: chat_id,
        //         integration_id: integration_id
        //     };
        //     dispatch(dialogsMessages(data))
        // }
    }

    const handleProfileInfo = (value) => {
        setProfileInfo(value);
    }

    useEffect(() => {
        if(user_id){
            const data = {
                user_id: user_id
            };
            dispatch(dialogsList(data))
            dispatch(dialogsLength(data))
        }
    }, [dispatch, user_id]);

    // console.log(dialogs_list)

    return (
        <>
        <div className="side-menu-tab">
            <div className="tab-content">
                {dialogs_list === null ? 
                    <>
                        <Stack spacing={2}>
                            <Skeleton variant="rounded" width={"100%"} height={24} />
                            <Skeleton variant="rounded" width={"100%"} height={56} />
                        </Stack>
                        <Stack spacing={2} sx={{mt: 3}}>
                            {Array(3).fill(null).map((_, index) => (
                                <Skeleton key={index} variant="rounded" width={"100%"} animation="wave" height={74} />
                            ))}
                        </Stack>
                    </>
                :
                    <PaneDialogs 
                        title='Диалоги'
                        placeholder='Поиск по диалогам'
                        length={dialogs_length}
                        list={dialogs_list}
                        loadProfileDialogstart={loadProfileDialogstart}
                        onClickHandlerChangeProfileChat={(value) => handleProfileDialogstart(value)}
                    />
                }
            </div>
        </div>
        
        {user_id !== 0 &&
            <>
                <div className={"layout-content" + (loadProfileDialogstart !== null ? ' active' : '')}>
                    {loadProfileDialogstart === null ? 
                    <ProfileChatStart user_id={user_id} username={username}/> :
                    <div className="chat-box">
                        <div className="container">
                            <div className="chat-wrap">
                                {dialogs_list !== null && loadProfileData !== null &&
                                <>
                                    <ProfileChatHeader 
                                        profile={dialogs_list[loadProfileData][0]}
                                        loadProfileInfo={loadProfileInfo}
                                        onClickHandlerChangeProfileInfo={(value) => handleProfileInfo(value)}
                                        onClickHandlerChangeProfileChat={(value) => handleProfileDialogstart(value)}
                                    />
                                    <div className="scrollbar-wrap bottom-scroll">
                                        <div className="chat-content">
                                            <div className="message-list">
                                                {dialogs_messages !== null && dialogs_messages.map((item, index) => (
                                                    <DialogsMessages key={index} messages={item}/>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                }
                                {/* <div className="chat-bottom">
                                    <div className="chat-form">
                                        <form action="#">
                                        <div className="chat-form-wrap">
                                            <div className="microphone">
                                                <button type="button"><i className="feather-mic"></i></button>
                                            </div>
                                            <div className="form-group">
                                                <input type="text" autoComplete="off" className="form-control" placeholder="Введите сообщение..." />
                                            </div>
                                            {/* <div className="emoji">
                                                <button type="button"><i className="feather-smile"></i></button>
                                            </div>
                                            <div className="chat-file">
                                                <button type="button" className="file-btn"><i className="feather-paperclip"></i></button>
                                                <input type="file" className="file-input" />
                                            </div> */}
                                            {/*<div className="send-btn">
                                                <button type="submit"><i className="feather-send"></i></button>
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>}
                </div>
                <div className={"chat-user-more offcanvas offcanvas-end" + (loadProfileInfo ? ' show' : '')} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="chatUserMore" aria-labelledby="chatUserMoreLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="chatUserMoreLabel">Информация</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>handleProfileInfo(!loadProfileInfo)}><i className="far fa-xmark"></i></button>
                    </div>
                    <div className="offcanvas-body scrollbar-wrap">
                        <div className="chat-user-info">
                            {user_id !== 0 && dialogs_list !== null && dialogs_list.length !== 0 && loadProfileData !== null &&
                            <ProfileMore 
                                profile={dialogs_list[loadProfileData][0]}
                                loadProfileInfo={loadProfileInfo}
                                onClickHandlerChangeProfileInfo={(value) => handleProfileInfo(value)}
                            />}
                        </div>
                    </div>
                </div>
            </>
        }
    </>
    )
}