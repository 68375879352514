import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
// import 'moment/locale/ru';
// import CardContacts from '../../../components/Card/CardContacts';

export default function integrationList(props) {

    const { item, onClickHandlerChangeProfileChat, idx } = props;

    console.log(item)

    return <>
        <div onClick={()=>{onClickHandlerChangeProfileChat(idx)}} className="chat-list-item" style={{
                background: (item[0]?.active != 'online' ? "#ebebeb" : "#ffffff"),
                cursor: "pointer"
            }}>
            <div className="chat-list-group">
                <div className="group-info">
                    <div className="group-avatar">
                        <div className="avatar">
                        <span className="avatar-text"><i className="feather-rss"></i></span>
                        </div>
                    </div>
                    <h5 className="group-name">{item[0]?.first_name}</h5>
                </div>
                <div className="avatar-group">
                    <div className="avatar">
                        <img src="assets/img/account/14.jpg" alt="" />
                    </div>
                    <div className="avatar">
                        <img src="assets/img/account/11.jpg" alt="" />
                    </div>
                    <div className="avatar">
                        <img src="assets/img/account/18.jpg" alt="" />
                    </div>
                    <div className="avatar">
                        <span className="avatar-text">+5</span>
                    </div>
                </div>
            </div>
            {/* <div className="chat-list-content">
                <div className="avatar">
                    <img src="assets/img/account/04.jpg" alt="" />
                    <span className="status online"></span>
                </div>
                <div className="chat-list-info">
                    <div className="chat-list-title">
                        <h5 className="name">Brandi Ingles</h5>
                        <span className="time">10:50 PM</span>
                    </div>
                    <div className="chat-list-message">
                        <p className="message-text">Hello! I'm sorry, I didn't catch that. Could you please repeat for me again?.</p>
                        <span className="message-count">03</span>
                    </div>
                </div>
            </div> */}
            <div className="bottom" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                    <div className="bottom-info">
                        <span className="group-status" style={{fontSize: "14px"}}>{item[0]?.active}</span>
                        {/* <p className="text">{item[0]?.title}</p> */}
                    </div>
                    <div className="avatar-group">
                        <small className="group-status">{moment(item[0]?.created_at).format('DD.MM.YYYY')}</small>
                        {/* <small className="group-status">13.07.2024</small> */}
                        {/* <div className="avatar">
                            <img src="assets/img/account/14.jpg" alt="" / />
                        </div>
                        <div className="avatar">
                            <img src="assets/img/account/11.jpg" alt="" / />
                        </div>
                        <div className="avatar">
                            <img src="assets/img/account/18.jpg" alt="" / />
                        </div>
                        <div className="avatar">
                            <span className="avatar-text">+5</span>
                        </div> */}
                    </div>
                </div>
        </div>
           
        </>
}