import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Global } from '@emotion/react';
import ChatDrawer from './Chat'
import ChatGrid from './ChatGrid'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import {selectSwipeable} from '../model/selectors/selectSwipeable'

const drawerBleeding = 56;

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
  width: 60,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: "16px",
  left: 'calc(50% - 30px)',
  display: 'none', // Initially hide the element
    '@media (max-width: 768px)': { // Apply styles for screens with width less than 768px
        display: 'block',
    },
}));

function MobileSwipeableDrawer(props) {
    const { winow, currentSwipeableDrawer, onClickHandlerSwipeableDrawer, onHandleLogoutPage } = props;
    // console.log()
    let cookie_drawer = 0;
    let username = 0;
    // Создаем реф для блока
    const scrollRef = useRef(null);
    const [drawerPercent, setDrawerPercent] = useState(100);  
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const swipeable_list = useSelector(selectSwipeable)
    const [open, setOpen] = useState(cookie_drawer);
    const container = winow !== undefined ? () => winow().document.body : undefined;

    let cookie_split = document.cookie.split(';');
    for (let i = 0; i < cookie_split.length; i++) {
        let cookie_name_split = cookie_split[i].split('=');  
        if(cookie_name_split[0].replace(/\s+/g, '') === "drawer"){
            cookie_drawer = cookie_name_split[1];
        }
        if(cookie_name_split[0].replace(/\s+/g, '') === "username"){
            username = cookie_name_split[1]
        }
    }

    const [dialogs_messages, setDialogsMessages] = useState([]);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setStartY(e.touches[0].clientY);
    };

    const handleTouchEnd = (e) => {
        const endX = e.changedTouches[0].clientX;
        const endY = e.changedTouches[0].clientY;
        const diffX = endX - startX;
        const diffY = endY - startY;

        if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX > 0) {
            // console.log('Swiped Right!');
        } else {
            // console.log('Swiped Left!');
        }
        } else {
        if (diffY > 0) {
            // console.log('Swiped Down!');
        } else {
            // console.log('Swiped Up!');
            if (scrollRef.current) {
                // console.log(`Current scrollTop: ${scrollRef.current.scrollTop}px`);
                // if(scrollRef.current.scrollTop > 300){
                //     setDrawerPercent(100);
                // }
                setDrawerPercent(100);
            }
        }
        }
    };
    
    const toggleDrawer = (newOpen) => () => {
        if(newOpen == 1){
            setDrawerPercent(100)
        } else {
            onClickHandlerSwipeableDrawer(null)
        }
        setOpen(newOpen);
        cookie_drawer = newOpen;
        document.cookie = `drawer=${newOpen}; path=/;`;
    };

    useEffect(() => {
        if(currentSwipeableDrawer === null){
            document.cookie = `drawer=0; path=/;`;
            setDrawerPercent(100)
            setOpen(0)
            cookie_drawer = 0;
        } 
        if(currentSwipeableDrawer !== null && open == 0 && cookie_drawer == 0){
            setOpen(1);
            cookie_drawer = 1;
            document.cookie = `drawer=1; path=/;`;
            setDrawerPercent(100);
            if (scrollRef.current) {
                scrollRef.current.scrollTop = 0;
            }
        }
    }, [open, cookie_drawer, props]);   

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [swipeable_list, dialogs_messages]);   

    return <>
        <CssBaseline />
        <Global
            styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
                height: `calc(${drawerPercent}% - ${drawerBleeding}px)`,
                overflow: 'visible',
                transition: "0.3s !important",
                display: 'none', // Initially hide the element
                '@media (max-width: 768px)': { // Apply styles for screens with width less than 768px
                    display: 'block',
                },
            },
            }}
        />
        <SwipeableDrawer
            container={container}
            anchor="bottom"
            open={(open == 0 ? false : true)}
            onClose={toggleDrawer(0)}
            onOpen={toggleDrawer(1)}
            swipeAreaWidth={drawerBleeding}
            allowSwipeInChildren={true}
            disableSwipeToOpen={true}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <StyledBox
                sx={{
                    position: 'absolute',
                    top: "-32px",
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    visibility: (open == 0 ? 'hidden' : 'visible'),
                    right: 0,
                    left: 0,
                    px: 2,
                    pt: 3,
                    pb: 3
                }}
            >
                <Puller />
            </StyledBox>
            <StyledBox
                ref={scrollRef}
                onTouchStart={handleTouchStart}
                // onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                sx={{
                    px: 2,
                    height: '100%',
                    overflowY: 'auto',
                }}
            >
                {currentSwipeableDrawer == 'chat-ai' && <ChatDrawer dialogs_messages={dialogs_messages} setDialogsMessages={(value) => setDialogsMessages(value)}/>}
                {/* {currentSwipeableDrawer == 'music' && <MusicPlayerSlider/>} */}
                {currentSwipeableDrawer == 'grid' && <ChatGrid onHandleLogoutPage={() => onHandleLogoutPage()}/>}
            </StyledBox>
        </SwipeableDrawer>
    </>
}

MobileSwipeableDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    winow: PropTypes.func,
};
  
export default MobileSwipeableDrawer;